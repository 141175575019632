// RHF
import { useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
// Theme
import useThemeStore from 'Theme/store';
// React
import toast from 'react-hot-toast';

const VariableSection = ({ selectedPrompt, runningSample }) => {
  const { register, setValue } = useFormContext();

  //const { register } = useFormContext();
  const { theme } = useThemeStore();

  const [textareaValues, setTextareaValues] = useState({});
  const defaultMaxLength = 10000;
  const [isToastVisible, setToastVisible] = useState(false);

  const handleTextareaChange = (e, maxLength) => {
    const { name, value } = e.target;

    if (typeof maxLength !== 'number') {
      maxLength = defaultMaxLength;
    }

    if (value.length > maxLength) {
      if (!isToastVisible) {
        toast.error(
          'Character limit exceeded: Please edit your input to fit within the allowed limit.',
          {
            icon: '⚠️',
          }
        );
        setToastVisible(true);
      }

      return;
    }

    if (isToastVisible) {
      setToastVisible(false);
    }

    setTextareaValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setValue(name, value);
  };

  const getPromptMaxLength = (prompt) => {
    if (typeof prompt.maxLength !== 'number') {
      return defaultMaxLength;
    }
    return prompt.maxLength;
  };

  const getCharCount = (text) => {
    if (typeof text !== 'string') {
      return 0;
    }
    return text.length;
  };

  useEffect(() => {
    selectedPrompt?.prompts.forEach((prompt) => {
      setValue(prompt.attr, '');
      setTextareaValues((prev) => ({
        ...prev,
        [prompt.attr]: '',
      }));
    });
  }, []);

  useEffect(() => {
    setTextareaValues({});
  }, [selectedPrompt]);

  useEffect(() => {
    if (runningSample) {
      selectedPrompt?.prompts.forEach((prompt) => {
        setValue(prompt.attr, prompt.example || '');
        setTextareaValues((prev) => ({
          ...prev,
          [prompt.attr]: prompt.example || '',
        }));
      });
    }
  }, [runningSample]);

  return (
    <div>
      {selectedPrompt?.prompts
        ?.filter((i) => !['image', 'attachment'].includes(i.type))
        ?.map((prompt, index) => {
          return (
            <div className="mb-4" key={index} style={{ marginRight: '10px' }}>
              <div className=" uppercase font-barlow mb-2 text-sm font-bold text-graphite-200 dark:text-graphite-100">
                {prompt?.title}
              </div>
              {['textarea', 'code'].includes(prompt?.type) && (
                <>
                  <textarea
                    name={prompt.attr}
                    className={`w-full ${
                      theme === 'dark'
                        ? 'bg-graphite-900 text-snow-100'
                        : 'bg-white text-graphite-900'
                    }  border border-snow-900 border-solid p-[8px] font-barlow rounded-md focus:border-flax-800 resize-y`}
                    placeholder={prompt.placeholder}
                    minLength={prompt.minLength}
                    required={prompt.required}
                    rows={prompt.type === 'code' ? 10 : 3}
                    cols={prompt.type === 'code' ? 50 : 0}
                    {...register(prompt.attr)}
                    onChange={(e) => handleTextareaChange(e, prompt.maxLength)}
                    value={textareaValues[prompt.attr] || ''}
                  />
                  <div className="flex gap-1 mt-1 font-lexend text-xs items-center justify-end">
                    <div
                      className={`${
                        theme === 'dark'
                          ? 'dark:text-white '
                          : 'text-graphite-400 '
                      } font-light`}
                    >
                      <span
                        className={`${
                          getCharCount(textareaValues[prompt.attr] || '') >
                            prompt.maxLength && 'text-crayola-600'
                        }`}
                      >
                        {getCharCount(
                          textareaValues[prompt.attr] || ''
                        ).toLocaleString('en-US')}
                      </span>
                      /{getPromptMaxLength(prompt).toLocaleString('en-US')}{' '}
                      characters
                    </div>
                  </div>
                </>
              )}
              {prompt.type === 'text' && (
                <input
                  name={prompt.attr}
                  className={`w-full border ${
                    theme === 'dark'
                      ? 'bg-graphite-900 text-snow-100'
                      : 'bg-white text-graphite-900'
                  }  border-snow-900 border-solid p-[8px] font-barlow rounded-md focus:border-flax-800`}
                  placeholder={prompt.placeholder}
                  maxLength={prompt.maxLength}
                  minLength={prompt.minLength}
                  required={prompt.required}
                  {...register(prompt.attr)}
                  onChange={(e) => handleTextareaChange(e, prompt.maxLength)}
                  value={textareaValues[prompt.attr] || ''}
                />
              )}
              {prompt.type === 'dropdown' && (
                <select
                  name={prompt.attr}
                  className={`w-full border ${
                    theme === 'dark'
                      ? 'bg-graphite-900 text-snow-100'
                      : 'bg-white text-graphite-900'
                  }  border-snow-900 border-solid p-[8px] font-barlow rounded-md focus:border-flax-800`}
                  placeholder={prompt.placeholder}
                  required={prompt.required}
                  {...register(prompt.attr)}
                  onChange={(e) => handleTextareaChange(e)}
                  value={textareaValues[prompt.attr] || ''}
                >
                  {prompt.options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
              {!['dropdown', 'text', 'textarea', 'code'].includes(
                prompt?.type
              ) &&
                prompt?.type !== 'attachments' && (
                  <input
                    name={prompt.attr}
                    className={`w-full border ${
                      theme === 'dark'
                        ? 'bg-graphite-900 text-snow-100'
                        : 'bg-white text-graphite-900'
                    }  border-snow-900 border-solid p-[8px] font-barlow rounded-md focus:border-flax-800`}
                    placeholder={prompt?.placeholder}
                    maxLength={prompt?.maxLength}
                    minLength={prompt?.minLength}
                    required={prompt?.required}
                    {...register(prompt.attr)}
                    onChange={(e) => handleTextareaChange(e, prompt.maxLength)}
                    value={textareaValues[prompt.attr] || ''}
                  />
                )}
            </div>
          );
        })}
    </div>
  );
};

export default VariableSection;
