import { Fragment, createRef, useEffect, useRef, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';
import Subheader from './Subheader';
import MainBody from './Body';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import ToolTipParent from './ToolTip';

import TextFieldControlled from './Forms/TextFieldControlled/TextFieldControlled';
import { useForm, useWatch } from 'react-hook-form';
// import SelectControlled from './Forms/SelectControlled';
import {
  UserCircleIcon,
  PencilSquareIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import Loader from './Loader';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import useClipboardComponent from 'Hooks/useClipboardComponent';

import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import Modal from './Common/Modal/Modal';
import { ClipboardIcon } from '@heroicons/react/24/solid';
import SelectMultipleControlled from './Forms/SelectMultipleControlled';
import useThemeStore from 'Theme/store';
import PercentageHandler from './Common/PercentageHandler/PercentageHandler';

const schemaValidationSignUp = yup.object({
  fname: yup
    .string()
    .required('First name is required')
    .matches(
      /^[A-Za-z0-9\s]+$/,
      'First name should only contain letters and numbers'
    ),
  lname: yup
    .string()
    .required('Last name is required')
    .matches(
      /^[A-Za-z0-9\s]+$/,
      'Last name should only contain letters and numbers'
    ),
  nickname_fpr: yup.string().url('Invalid URL').nullable(true),
});

const getLimitValues = (values) => {
  const temp = JSON.parse(JSON.stringify(values));
  delete temp.words;
  return temp;
};

const UserSettings = inject('store')(
  observer(({ store }) => {
    const wordcapLimitsSection = useRef(null);

    const [models, setModels] = useState([]);
    const [loadingModels, setLoadingModels] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const {
      lname,
      fname,
      email,
      default_model,
      nickname_fpr,
      plan,
      api_key,
      multi_default_models,
      wordcap_limits,
    } = store.profile;

    const setProfile = store.setProfile;

    const [firstname, setFirstName] = useState(fname);
    const [lastname, setLastName] = useState(lname);
    const [wordcapLimits, setWordcapLimits] = useState(
      getLimitValues(wordcap_limits)
    );
    const getImageProfile = () => store.profile.avatar.svg;
    const navigate = useNavigate();

    const { copy } = useClipboardComponent();

    const { control, handleSubmit, getValues, setValue } = useForm({
      defaultValues: {
        api_key: api_key,
        email: email,
        fname: firstname,
        lname: lastname,
        default_model: [default_model],
        nickname_fpr: nickname_fpr
          ? `https://platform.straico.com/signup?fpr=${nickname_fpr}`
          : 'https://platform.straico.com/signup?fpr=',
        multi_default_models:
          multi_default_models.length > 0
            ? multi_default_models
            : [default_model],
        wordcap_limits: wordcap_limits,
      },
      resolver: yupResolver(schemaValidationSignUp),
    });

    const watchedWords = useWatch({
      control,
      name: 'wordcap_limits',
    });

    useEffect(() => {
      if (wordcapLimitsSection.current) {
        wordcapLimitsSection.current.id = 'wordcapLimitsSection';
      }
    }, []);

    // Helper function to check if a string is a valid URL
    function isURL(str) {
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
      return urlRegex.test(str);
    }

    // Helper function to split the path and extract the 'fpr' value
    function getFprValueFromURL(url) {
      if (!isURL(url)) return null;
      const newUrl = new URL(url);
      const fpr = newUrl.searchParams.get('fpr');
      return fpr;
    }

    const viabilityMultiDefault = (multi_default_models) => {
      // Filter models based on IDs in multi_default_models
      const defaultModels = models.filter((model) =>
        multi_default_models.includes(model.id)
      );

      // Filter default models that are enabled
      const enabledModels = defaultModels.filter((model) => model.enabled);

      if (enabledModels.length > 0) {
        return enabledModels.map((model) => model.id);
      } else {
        // If no enabled default models, fallback based on conditions
        if (!default_model.enabled) {
          if (store.profile.plan === 'free') {
            return ['65f377dcaa4feb73a4ae62ac'];
          } else {
            return ['6620346cecf94d7b874019f2'];
          }
        } else {
          return [default_model.id];
        }
      }
    };

    const onSubmit = async (data) => {
      setSubmitLoading(true);
      try {
        let fprValue = null;
        if (data.nickname_fpr) fprValue = getFprValueFromURL(data.nickname_fpr);

        // if(data.multi_default_models.length === 0){

        // }
        const res = await store.api.post(`/user/update-user`, {
          lname: data.lname,
          fname: data.fname,
          default_model: data.default_model,
          multi_default_models: data.multi_default_models,
          nickname_fpr: fprValue,
          wordcap_limits: getValues('wordcap_limits'),
        });
        localStorage.removeItem('currentModel');

        if (res.status === 200) {
          localStorage.setItem('profile', JSON.stringify(res.data));
          setFirstName(res.data.fname);
          setLastName(res.data.lname);
          setSubmitLoading(false);
          store.profile.nickname_fpr = res.data.nickname_fpr;
          store.profile.wordcap_limits = res.data.wordcap_limits;
          toast.success('Profile updated');
        } else {
          toast.success('Updating error');
        }
      } catch (error) {
        toast.error(
          'Updating error, check that your referral link is valid or change it, to a unique one'
        );
      } finally {
        setSubmitLoading(false);
      }
    };

    useEffect(() => {
      (async () => {
        try {
          const response = await store.api.get(`/model`);
          let availableModels = response.data.models.filter(
            (model) => model.enabled
          );
          let transformedModels;
          if (store.profile.plan == 'freeTos') {
            const res = await store.api.get('/user/constant', {
              params: {
                type: 'princing_plans',
              },
            });
            let freePlanModels = []; // Array to store models allowed for free users
            freePlanModels = res.data.rules[0].models;
            const modelsInFreePlan = []; // Array to store models available for free users
            const otherModels = []; // Array to store models not available for free users
            availableModels.forEach((model) => {
              if (freePlanModels.includes(model.model)) {
                modelsInFreePlan.push(model);
              } else {
                otherModels.push(model);
              }
            });
            transformedModels = modelsInFreePlan.map((model) => ({
              id: model._id,
              title: model.name,
            }));
          } else {
            transformedModels = response.data.models.map((model) => ({
              id: model._id,
              title: model.name,
            }));
          }
          control.multi_default_models =
            viabilityMultiDefault(multi_default_models);
          setModels(transformedModels);
          setLoadingModels(false);
        } catch (error) {
          console.log(error);
        }
      })();
    }, []);

    const handleCopy = (e) => {
      try {
        e.preventDefault();
        copy(store.profile.api_key);
        toast.success('API key copied to clipboard');
      } catch (error) {
        toast.error('Failed to copy link');
      }
    };

    const resetApiKey = async () => {
      try {
        const res = await store.api.post(`/user/change-api-key`, {});
        if (res.status === 200) {
          store.profile.api_key = res.data.api_key;
          setValue('api_key', res.data.api_key);
          toast.success('API key updated');
        } else {
          toast.success('Updating error');
        }
      } catch (error) {
        toast.error('Updating error');
      }
    };

    const [isOpen, setIsOpen] = useState(false);
    const closeModal = () => setIsOpen(false);
    const openModal = () => setIsOpen(true);

    const { theme: themeValue } = useThemeStore();

    const changeLimits = (newValues) => {
      const currentValues = getValues('wordcap_limits');
      setWordcapLimits(newValues);
      setValue('wordcap_limits', { ...currentValues, ...newValues });
    };

    const CustomTooltip = ({ children, title }) => {
      const [isVisible, setIsVisible] = useState(false);
      const { theme: themeValue } = useThemeStore(); // Usando el valor del tema directamente

      const containerStyle = {
        position: 'relative',
        display: 'inline-block',
      };

      const handleToggleTooltip = () => {
        setIsVisible((prevVisible) => !prevVisible);
      };

      return (
        <div style={containerStyle} onClick={handleToggleTooltip}>
          {children}
          <div
            className={`absolute z-10 p-3 text-sm leading-none shadow-md text-center rounded-md transition-all ${
              themeValue === 'dark'
                ? 'bg-zinc-800 text-white'
                : 'bg-white text-graphite-800'
            }`}
            style={{
              bottom: '-225%',
              textAlign: 'left',
              left: '20%',
              transform: 'translateX(-100%)',
              display: isVisible ? 'block' : 'none',
              maxWidth: '150px',
              width: 'max-content',
              wordWrap: 'break-word',
              whiteSpace: 'normal',
            }}
          >
            {title}
          </div>
        </div>
      );
    };

    return (
      <>
        <Helmet>
          <title>{'My Profile - Straico'}</title>
        </Helmet>
        <Subheader
          title="My Profile"
          description="Get control of your profile and personalize it"
          icon="Cog8ToothIcon"
        />
        <div className="">
          {loadingModels ? (
            <div className="absolute inset-0 flex items-center justify-center h-full">
              <Loader active className="m-auto w-64 h-64 pr-10" />
            </div>
          ) : (
            <MainBody className="px-4 py-4 md:px-28 md:py-4 lg:py-4">
              <div className="text-lg font-bold font-lexend uppercase text-eggplant-700 dark:text-flax-900 ">
                Your Personal hub{' '}
              </div>
              <div
                className={`text-base font-normal font-barlow mb-6 ${
                  themeValue == 'dark' ? 'dark:text-white' : 'text-graphite-800'
                }`}
              >
                Manage your identity & preferences{' '}
              </div>
              <div className="w-90vw mx-auto flex flex-row justify-between items-center">
                {/* Column 1: Image and Information */}
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <div className="w-16 h-16 rounded-full overflow-hidden">
                      {store.profile.avatar.svg ? (
                        <div className="avatar">
                          <div className="w-16 h-16 rounded-full overflow-hidden">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: getImageProfile(),
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <UserCircleIcon className="w-16 h-16transition text-graphite-400" />
                      )}
                    </div>

                    <div
                      className="absolute -bottom-2 -right-1 rounded-full bg-crayola-700 w-6 h-6 flex items-center justify-center"
                      onClick={() => {
                        navigate('/my-avatar');
                      }}
                    >
                      <PencilSquareIcon className="cursor-pointer w-4 h-4 text-white bg-crayola-600 rounded-full" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div
                      className={`text-lg font-bold font-lexend uppercase ${
                        themeValue == 'dark'
                          ? 'dark:text-white'
                          : 'text-graphite-800'
                      }`}
                    >{`${firstname} ${lastname}`}</div>
                    <div
                      className={`text-sm  font-barlow font-normal ${
                        themeValue == 'dark'
                          ? 'dark:text-white'
                          : 'text-graphite-800'
                      }`}
                    >
                      {email}
                    </div>
                  </div>
                </div>

                {/* Column 2: Button */}
                {/* <button
                className="px-4 py-2 text-white text-lg rounded-md hover:bg-[#A0552F] bg-crayola-600 font-lexend-deca font-bold uppercase  shadow-md focus:ring focus:ring-opacity-50"
                onClick={() => {
                  navigate('/my-avatar');
                }}
              >
                Change avatar
              </button> */}
              </div>

              <form
                className="mx-auto flex flex-col justify-between space-y-6"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="text-md font-bold mt-8 mb-4 font-lexend uppercase p-2 rounded">
                  identity essentials
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextFieldControlled
                    name="email"
                    control={control}
                    label="Email"
                    textFieldProps={{
                      fullWidth: true,
                      disabled: true,
                    }}
                    showLockIcon={true}
                  />
                  <CustomTooltip
                    title={
                      <>
                        Your email is the key to login. Do you want to change
                        it? Contact us at{' '}
                        <a
                          className="text-crayola-700 cursor-pointer"
                          href="mailto:hello@straico.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          hello@straico.com
                        </a>
                      </>
                    }
                  >
                    <InformationCircleIcon
                      className={`w-4 h-4 ml-2 ${
                        themeValue === 'dark'
                          ? 'text-white'
                          : 'text-graphite-800'
                      }`}
                    />
                  </CustomTooltip>
                </div>
                <TextFieldControlled
                  name="lname"
                  control={control}
                  label="Last name"
                />
                <div className="text-md font-bold mt-8 font-lexend uppercase text-graphite-800 bg-flax-500 p-2 rounded">
                  Your preferences
                </div>
                <div>
                  <p
                    className={`font-barlow ${
                      themeValue == 'dark'
                        ? 'dark:text-white'
                        : 'text-graphite-800'
                    }`}
                  >
                    Your interactions with AI should be as individual as you
                    are. Select{' '}
                    <strong>your default Large Language Model (LLM)</strong>,
                    crafting a personalized path of discovery and interaction
                    for all your chats.
                  </p>
                </div>
                <SelectMultipleControlled
                  control={control}
                  name="multi_default_models"
                  options={models}
                  disabled={plan === 'free' ? true : false}
                />
                <div className="text-md font-bold mt-8 mb-4 font-lexend uppercase text-graphite-800 bg-vanilla-400 p-2 rounded">
                  Boost your influence
                </div>
                <p
                  className={`font-barlow ${
                    themeValue == 'dark'
                      ? 'dark:text-white'
                      : 'text-graphite-800'
                  }`}
                >
                  Save <strong>your personal referral link</strong> here, and it
                  will become a silent partner in every chat and template you
                  share.{' '}
                  <a
                    className="text-crayola-700 cursor-pointer"
                    onClick={openModal}
                  >
                    Need help finding your referral link?
                  </a>
                </p>
                <TextFieldControlled
                  name="nickname_fpr"
                  control={control}
                  label="Referral link"
                />
                <p
                  className={`font-barlow ${
                    themeValue == 'dark'
                      ? 'dark:text-white'
                      : 'text-graphite-800'
                  }`}
                >
                  Not yet signed up? Embark on the path to monetizing your
                  influence by joining our{' '}
                  <a
                    className="text-crayola-700 cursor-pointer"
                    href="https://straico.com/affiliate/"
                    target="__blank"
                  >
                    affiliate program.
                  </a>
                </p>
                {store.profile.api_key ? (
                  <>
                    <div className="text-md font-bold mt-8 mb-4 font-lexend uppercase text-white bg-eggplant-300 p-2 rounded">
                      Connect with Straico API
                    </div>
                    <p
                      className={`font-barlow ${
                        themeValue == 'dark'
                          ? 'dark:text-white'
                          : 'text-graphite-800'
                      }`}
                    >
                      Dive into Straico's world with our{' '}
                      <strong>easy-to-use API.</strong> It's like adding a
                      supercharged AI toolkit to your projects, giving you the
                      power to do more with less hassle. Perfect for anyone
                      looking to sprinkle a bit of AI magic into their work. For
                      full access and guidelines, visit our API documentation
                      and resource center:{' '}
                      <a
                        className="text-crayola-700 cursor-pointer"
                        href="https://documenter.getpostman.com/view/5900072/2s9YyzddrR"
                        target="__blank"
                      >
                        Straico API Documentation.
                      </a>
                    </p>
                    <div className="flex items-center w-full">
                      <div className="flex-grow w-full">
                        <TextFieldControlled
                          disabled={true}
                          name="api_key"
                          control={control}
                          label="API key"
                          fullWidth={true}
                        />
                      </div>
                      <button
                        className="flex items-center justify-center w-8 h-8 rounded-full bg-eggplant-300 hover:bg-eggplant-600 ml-2 flex-shrink-0"
                        onClick={handleCopy}
                      >
                        <ClipboardIcon className="w-5 h-5 text-white" />
                      </button>
                    </div>
                    <div className={`flex justify-end w-full -mb-8`}>
                      <Button
                        type="button"
                        title={'Reset API key'}
                        noMarginTop
                        onClick={() => resetApiKey()}
                      />
                    </div>
                    <p
                      className={`font-barlow ${
                        themeValue == 'dark'
                          ? 'dark:text-white'
                          : 'text-graphite-800'
                      }`}
                    >
                      Treat your API key like a secret. Misplaced it? Contact us
                      quickly at{' '}
                      <a
                        className="text-crayola-700 cursor-pointer"
                        href="mailto: hello@straico.com"
                        target="__blank"
                      >
                        hello@straico.com
                      </a>
                    </p>
                  </>
                ) : (
                  <></>
                )}

                <div
                  ref={wordcapLimitsSection}
                  className="text-md font-bold mt-8 mb-4 font-lexend uppercase text-white bg-silver-700 p-2 rounded"
                >
                  Wordcap Limit
                </div>
                <p
                  className={`font-barlow ${
                    themeValue == 'dark'
                      ? 'dark:text-white'
                      : 'text-graphite-800'
                  }`}
                >
                  Manage{' '}
                  <strong>how many words are included in your requests</strong>{' '}
                  to optimize your Straico coin usage.{' '}
                  <strong>Recommended limit is 1,000 words per request.</strong>{' '}
                  If you set the limit to 0, the wordcap will be disabled, and
                  word capacity will then depend on the model's specific limits.{' '}
                  <a
                    className="font-semibold text-crayola-600 underline"
                    href="https://straico-uqhq.updates.userguiding.com/post/wordcap-limit-%F0%9F%92%AC-v1.36.0-830"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </p>
                <TextFieldControlled
                  name="wordcap_limits.words"
                  control={control}
                  label="Words per request"
                />
                <p
                  className={
                    themeValue == 'dark'
                      ? 'dark:text-white'
                      : 'text-graphite-800'
                  }
                >
                  Use the slider below to configure the percentage distribution
                  for each component of the Wordcap Limit:
                </p>
                <PercentageHandler
                  attributes={wordcapLimits}
                  words={getValues('wordcap_limits').words}
                  onChange={changeLimits}
                />

                <div className="w-full pt-8">
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={submitLoading}
                    sx={{
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '18px',
                      fontFamily: 'lexend deca',
                      textTransform: 'uppercase',
                    }}
                  >
                    Save Changes
                  </LoadingButton>
                </div>
              </form>
              <Modal
                open={isOpen}
                noIconTitle
                title="where to find your affiliate referral link?"
                onClose={closeModal}
              >
                <div className="flex flex-col gap-4 mt-[10px]">
                  <p className="text-graphite-800 font-barlow">
                    This feature is exclusively for members of{' '}
                    <strong>Straico's affiliate program.</strong> For details on
                    the benefits and enrollment process,{' '}
                    <a
                      className="text-crayola-700 cursor-pointer"
                      href="https://straico.com/affiliate/"
                      target="__blank"
                    >
                      click here.
                    </a>
                  </p>
                  <img
                    onClick={() =>
                      window.open(
                        'https://straico.firstpromoter.com/',
                        '__blank'
                      )
                    }
                    src="/afiliate_images.png"
                    alt="affiliate"
                    className="cursor-pointer"
                    style={{
                      boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
                    }}
                  />
                  <p className="text-graphite-800 font-barlow">
                    With your referral link embedded, every chat and template
                    URL you share has the potential to boost your earnings.
                    Start sharing and watch your rewards grow! 🚀
                  </p>
                </div>
              </Modal>
            </MainBody>
          )}
        </div>
      </>
    );
  })
);

export default UserSettings;
