/* eslint-disable no-constant-condition */
import { observer, inject } from 'mobx-react';
import { useEffect } from 'react';
import { withRouter, useNavigate } from 'react-router-dom';
// Logo
import OrangeLogo from 'Theme/icons/orangeLogo';
//RHF
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//Yup
import * as yup from 'yup';
// Components
import Button from '../../Components/Button';
import SelectControlled from '../../Components/Forms/SelectControlled/SelectControlled';
import CheckboxControlled from '../../Components/Forms/CheckboxControlled/CheckboxControlled';

import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = {
  menuItemText: {
    whiteSpace: 'normal', // Permite que el texto ocupe más de una línea
    wordBreak: 'break-word', // Rompe las palabras largas para ajustarse al contenedor
  },
};

const isValidString = (str) => {
  return str && str.trim() !== '' && str !== null && str !== undefined;
};

const findIdByName = (array, name) => {
  const item = array.find((item) => item.name === name);
  return item ? item.id : null;
};

const distributeValues = (categories) => {
  let welcomeUseOne = '';
  let welcomeUseTwo = '';
  let welcomeUseThree = '';

  let nonOtherIndex = 0;

  categories.forEach((category) => {
    if (category != 'Other') {
      if (nonOtherIndex === 0) {
        welcomeUseOne = category;
      } else if (nonOtherIndex === 1) {
        welcomeUseTwo = category;
      } else if (nonOtherIndex === 2) {
        welcomeUseThree = category;
      }
      nonOtherIndex++;
    }
  });

  return { welcomeUseOne, welcomeUseTwo, welcomeUseThree };
};

const itemsPurpose = [
  {
    id: 'idea_generation',
    name: 'Idea Generation',
    subtitle: 'Create proposals, campaigns, product updates, website layouts.',
  },
  {
    id: 'product_management',
    name: 'Product Management',
    subtitle:
      'Create roadmaps, feature specifications, technical feasibilities, prototypes.',
  },
  {
    id: 'search_engine_optimization',
    name: 'Search Engine Optimization',
    subtitle:
      'Create Keyword research, optimize page content, meta tags and descriptions.',
  },
  {
    id: 'content_generation',
    name: 'Content Generation',
    subtitle:
      'Create feedback surveys, drafting emails, social media, blog posts.',
  },
  {
    id: 'summarization',
    name: 'Summarization',
    subtitle:
      'Create summaries of academic journals, legal documents, database entries.',
  },
  {
    id: 'other',
    name: 'Other',
    subtitle: 'The posibilites are endless, write in the next field.',
  },
];

const itemsWho = [
  { id: 'personal', name: 'Personal' },
  { id: 'organization', name: 'Organization' },
];

const itemsSize = [
  { id: 'just_me', name: 'Just me' },
  { id: 'less_than_10', name: 'Less than 10' },
  { id: 'between_11_and_50', name: 'Between 11 and 50' },
  { id: 'more_than_50', name: 'More than 50' },
];

const validationSchemaWelcome = yup.object().shape({
  agree_mailing_list: yup.boolean(),
  age_checked: yup.boolean().oneOf([true], 'You must confirm your age.'),
  agree_terms_and_conditions: yup
    .boolean()
    .oneOf([true], 'You must agree to the terms and conditions.'),
  // primarily_use_pr: yup
  //   .string()
  //   .required('Please select how you primarily use Straico.'),
  // kind_of_work: yup.string().required('Please select your kind of work.'),
});

const Welcome = inject('store')(
  observer(({ store }) => {
    let navigate = useNavigate();
    // Form Welcome logic with RHF
    const { control, handleSubmit, setValue } = useForm({
      defaultValues: {
        state: '',
        agree_mailing_list: true,
        age_checked: true,
        agree_terms_and_conditions: true,
        // primarily_use_pr: '',
        // kind_of_work: '',
      },
      resolver: yupResolver(validationSchemaWelcome),
    });

    const [purposeName, setPurposeName] = React.useState([]);
    const [otherName, setOtherName] = React.useState('');
    const [whoName, setWhoName] = React.useState('');
    const [sizeName, setSizeName] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState(false);

    const nextState = (state) => {
      switch (state) {
        case 'modal_beta':
          return 'beta';
        case 'modal_join_list':
          return 'join_list';
        default:
          return state;
      }
    };

    useEffect(() => {
      setValue('state', nextState(store.profile.continue));
    }, []);

    const onSubmit = async (data) => {
      let valid = true;

      // Validate question 1 and 2
      if (purposeName.length > 0 && isValidString(whoName)) {
        //Validate other
        if (purposeName.includes('Other')) {
          if (!isValidString(otherName)) {
            valid = false;
          }
        }
        //Validate organization
        if (whoName == 'Organization') {
          if (!isValidString(sizeName)) {
            valid = false;
          }
        }
      } else {
        valid = false;
      }

      if (valid) {
        setErrorMessage(false);

        const { welcomeUseOne, welcomeUseTwo, welcomeUseThree } =
          distributeValues(purposeName);

        let newData = {
          state: 'beta',
          agree_mailing_list: true,
          age_checked: true,
          agree_terms_and_conditions: true,
          welcome_use_one: findIdByName(itemsPurpose, welcomeUseOne),
          welcome_use_two: findIdByName(itemsPurpose, welcomeUseTwo),
          welcome_use_three: findIdByName(itemsPurpose, welcomeUseThree),
          welcome_use_other: isValidString(otherName) ? otherName : '',
          welcome_who_item: findIdByName(itemsWho, whoName),
          welcome_who_size: findIdByName(itemsSize, sizeName),
        };

        // console.log('-----------------------------');
        // console.log(valid);
        // console.log(newData);
        // console.log('-----------------------------');

        try {
          await store.api.post('/user/beta', newData);
          if (store.profile.scope == 'appsumo') {
            navigate('/my-profile/pricing');
          }
          store.refreshTokenAndProfile();
          window.userGuiding.previewGuide(108975);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      } else {
        // console.log('Errorr');
        setErrorMessage(true);
      }
    };

    const handleChangePurpose = (event) => {
      const {
        target: { value },
      } = event;
      setOtherName('');
      if (value.length <= 3) {
        setPurposeName(typeof value === 'string' ? value.split(',') : value);
      }
    };

    const handleOtherChange = (event) => {
      setOtherName(event.target.value);
    };

    const handleChangeWho = (event) => {
      setSizeName('');
      setWhoName(event.target.value);
    };

    const handleChangeSize = (event) => {
      setSizeName(event.target.value);
    };

    return (
      <div className="fixed bottom-0 top-[50px] md:top-['none'] inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="welcome-wrapper bg-white rounded-lg max-w-3xl p-9 h-[840px] shadow-xl transform transition-all overflow-auto">
          <div className="mb-[16px]">
            <OrangeLogo />
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-[16px]"
          >
            <div>
              <p className="text-eggplant-700 text-lg font-lexend font-medium leading-7">
                Welcome, {store.profile.fname + ' ' + store.profile.lname}!{' '}
                <br />
                Get ready to unlock the full potential of generative AI.
              </p>
            </div>
            <div>
              <p className="text-graphite-400 text-base font-barlow font-normal leading-5 text-justify">
                All the AI tools you need in one place. Boost your productivity,
                unleash your creativity, and stay ahead of the competition with
                ease.{' '}
                <strong className="font-bold text-graphite-300">
                  A world of limitless possibilities right at your fingertips!{' '}
                </strong>
                <br />
              </p>
            </div>
            <div>
              <p className="text-eggplant-700 text-lg font-lexend font-medium leading-7">
                First, tell us a little about yourself.
              </p>
            </div>
            <div className="flex flex-col gap-[16px]">
              <div className="flex flex-col gap-[10px]">
                <p className=" text-graphite-400 font-semibold font-lexend text-sm">
                  What is the main usage you're going to use Straico for?*
                  <span className="font-light"> (select up to 3)</span>
                </p>

                <FormControl sx={{}}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Question 1
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={purposeName}
                    onChange={handleChangePurpose}
                    input={<OutlinedInput label="Question 1" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {itemsPurpose.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        <Checkbox
                          checked={purposeName.indexOf(item.name) > -1}
                        />
                        <ListItemText
                          primary={item.name}
                          secondary={item.subtitle}
                          primaryTypographyProps={{
                            style: styles.menuItemText,
                          }}
                          secondaryTypographyProps={{
                            style: styles.menuItemText,
                          }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {purposeName.includes('Other') && (
                  <>
                    <p className=" text-graphite-400 font-semibold font-lexend text-sm">
                      Give us more details about the other option*
                    </p>
                    <TextField
                      label="Specify Other"
                      value={otherName}
                      onChange={handleOtherChange}
                      variant="outlined"
                      sx={{ width: '100%' }}
                    />
                  </>
                )}
              </div>
              <div className="flex flex-col gap-[10px]">
                <p className=" text-graphite-400 font-semibold font-lexend text-sm">
                  Is the main usage you're going to use Straico is for an
                  organization or personal?*
                </p>

                <FormControl sx={{ mb: 2, minWidth: 120 }}>
                  <InputLabel id="demo-single-select-label">
                    Question 2
                  </InputLabel>
                  <Select
                    labelId="demo-single-select-label"
                    id="demo-single-select"
                    value={whoName}
                    onChange={handleChangeWho}
                    input={<OutlinedInput label="Question 2" />}
                  >
                    {itemsWho.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {whoName == 'Organization' && (
                  <>
                    <p className=" text-graphite-400 font-semibold font-lexend text-sm">
                      How many people form part of your organization?*
                    </p>
                    <FormControl sx={{ mb: 2, minWidth: 120 }}>
                      <InputLabel id="organization-select-label">
                        Size
                      </InputLabel>
                      <Select
                        labelId="organization-select-label"
                        id="organization-select"
                        value={sizeName}
                        onChange={handleChangeSize}
                        input={<OutlinedInput label="Size" />}
                      >
                        {itemsSize.map((item) => (
                          <MenuItem key={item.name} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}

                {/* <SelectControlled
                  control={control}
                  name="primarily_use_pr"
                  options={[
                    {
                      id: 'work',
                      title: 'For work',
                    },
                    {
                      id: 'personal_use',
                      title: 'For personal use',
                    },
                    {
                      id: 'personal_business',
                      title: 'For my personal business/startup',
                    },
                  ]}
                /> */}
              </div>
              <div className="flex flex-col gap-[8px]">
                {/* <CheckboxControlled
                  name="agree_mailing_list"
                  control={control}
                  formControlLabelProps={{
                    className: 'text-graphite-500 font-barlow',
                  }}
                  label="Join our mailing list"
                /> */}
                <CheckboxControlled
                  name="age_checked"
                  control={control}
                  formControlLabelProps={{
                    className: 'text-graphite-500 font-barlow',
                  }}
                  label="Confirm that you’re older than 14*"
                />
                <CheckboxControlled
                  name="agree_terms_and_conditions"
                  control={control}
                  formControlLabelProps={{
                    className: 'text-graphite-500 font-barlow',
                  }}
                  label={
                    <p>
                      Accept Straico’s{' '}
                      <a
                        href="https://straico.com/terms-and-conditions/"
                        target="__blank"
                        className="text-flax-900 underline"
                      >
                        terms and conditions
                      </a>
                      *
                    </p>
                  }
                />
              </div>
            </div>
            <div>
              <p className="text-eggplant-700 text-lg font-lexend font-medium leading-7">
                Thanks for joining the Straico community!
              </p>
            </div>
            {errorMessage ? (
              <>
                <p className="text-red-400 font-barlow text-sm">
                  You must fill all fields with a * character
                </p>
              </>
            ) : (
              <></>
            )}
            <div className="flex justify-center md:justify-start items-center md:items-start">
              <Button
                type="submit"
                title={'Get Started Now!'}
                CustomIcon={
                  <img
                    src="/logo-symbol-snow-100.svg"
                    className="cursor-pointer duration-500 undefined h-6 w-6 ml-2.5 mt-2"
                    alt="logo symbol"
                  />
                }
              />
            </div>
          </form>
          {/* store.profile.continue === 'modal_beta' */}
          {/* {store.profile.continue === 'modal_beta' ? (
            <></>
          ) : (
            <>
              {store.profile.continue === 'modal_join_list' ? (
                <div className="content-welcome">
                  <div className="mb-24">
                    <div className="sm:flex">
                      <div className="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 bg-gray-300">
                        <RocketLaunchIcon
                          className="h-6 w-6 text-gray-500"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-6">
                        <p>
                          Hello{' '}
                          <span className="font-bold">
                            {store.profile.fname + ' ' + store.profile.lname}
                          </span>
                        </p>
                        <p className="mb-4">
                          Thank you for joining us! We are excited to announce
                          that we will be going live on{' '}
                          <span className="font-bold">April 12th!</span>
                        </p>
                      </div>
                    </div>

                    <p className="mb-4">
                      Please note that our beta testing is now closed, but if
                      you join our waitlist, you will receive{' '}
                      <span className="font-bold">
                        {' '}
                        three months of our standard plan for free and a 20%
                        discount on any plan for one year
                      </span>{' '}
                      🙂
                    </p>

                    <p className="mb-4">
                      Thank you for your interest in our tool!
                    </p>
                  </div>

                  <div>
                    <div className="flex mb-4">
                      <div className="flex items-center h-5">
                        <input
                          id="agree_mailing_list"
                          type="checkbox"
                          value="agree_mailing_list"
                          name="agree_mailing_list"
                          className="w-4 h-4"
                        />
                      </div>
                      <div className="ml-2 text-sm">
                        <label htmlFor="agree_mailing_list">
                          I agree to join Straico’s mailing list
                        </label>
                      </div>
                    </div>
                    <Button title={'JOIN THE WAITLIST'} onClick={onJoinList} />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="mb-24">
                    <p className="">
                      Hello{' '}
                      <span className="font-bold">
                        {store.profile.fname + ' ' + store.profile.lname}
                      </span>
                    </p>

                    <p className="mb-4">
                      Please note that our beta testing is now closed, you are
                      currently on our waitlist.
                    </p>

                    <p className="mb-4">
                      Thank you for your interest in our tool!
                    </p>
                  </div>
                  <Button
                    title={'LOG OUT'}
                    onClick={() => store.handleLogout()}
                  />
                </div>
              )}
            </>
          )} */}
        </div>
      </div>
    );
  })
);

export default Welcome;
