import {
  TagIcon,
  UserCircleIcon,
  RocketLaunchIcon,
  BoltIcon,
  PaperClipIcon,
  SparklesIcon,
} from '@heroicons/react/24/solid';
import { useChat } from 'Context/Chat/Chat.context';
import { useEffect } from 'react';
import Emoji from 'react-emojis';
import { useNavigate } from 'react-router-dom';
import useThemeStore from 'Theme/store';
import Tooltip from '@mui/material/Tooltip';

const TemplateFav = ({ tool, isSmallMobile }) => {
  // TODO cuando hace onClick tiene que abir el tool
  const navigate = useNavigate();

  const { theme: themeValue } = useThemeStore();

  const {
    setSelectedTool,
    toggleShowPromptModal,
    setShowPromptList,
    sidebarTools,
    showPromptSide: show,
    toggleShowPromptSide: toggleSide,
    closeShowPrompSide: closeSide,
    loadingSideTools,
    setShowMobilePrompts,
  } = useChat();

  const handleSelectPrompt = () => {
    const selectedToolLocal = tool;
    setSelectedTool(selectedToolLocal);
    toggleShowPromptModal();
    setShowPromptList(false);
  };

  const onClickTemplate = (e) => {
    setShowMobilePrompts(false);
    handleSelectPrompt();
    // url params
    e.preventDefault();
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('selectedTool', tool?._id);
    navigate(
      {
        pathname: location.pathname,
        search: urlParams.toString(),
      },
      { shallow: true }
    );
  };

  const veriftyEmoji = (tool) => {
    let iconTag = 'high-voltage';
    if (tool.Icon) {
      if (tool.Icon !== ' ') {
        iconTag = tool.Icon;
      }
    }
    return <Emoji emoji={iconTag} />;
  };

  const imageGenerationTools = [
    '65805947d92e370a3d5625c6',
    '66c786d7e26442bc593e575d',
  ];

  const setImageToolNames = (tool) => {
    if (tool?.id == '65805947d92e370a3d5625c6') {
      return 'OpenAi: Dall-E 3';
    } else if (tool?.id == '66c786d7e26442bc593e575d') {
      return 'Fal-Ai: FLUX1.1 [pro]';
    }
    return '';
  };

  return (
    <div
      className={`${
        themeValue == 'dark' ? 'bg-graphite-900' : 'bg-white'
      }  rounded-lg px-4 py-5 w-[308px] border border-snow-900 dark:border-snow-100 m-4 cursor-pointer hover:scale-102`}
      onClick={onClickTemplate}
    >
      <div className="flex items-center space-x-2 text-graphite-100 dark:text-flax-900">
        <span className="rounded-full">
          <TagIcon className={'h-4 w-6 '} />
        </span>
        <h2 className="text-sm font-lexend uppercase font-bold">
          {tool.category}
        </h2>
      </div>
      <div className="flex items-center space-x-2">
        <div className="rounded-full text-3xl mt-4 mb-2">
          {veriftyEmoji(tool)}
          {/* {tool?.Icon !== '' && <Emoji emoji={tool.Icon} />} */}
        </div>
        <h2 className="font-lexend text-sm font-medium leading-5 text-left text-graphite-800 dark:text-snow-100">
          {tool.title}
        </h2>
      </div>
      <div className="flex items-center space-x-2"></div>
      <div className="mt-2">
        {/* <div className="w-fit bg-snow-900 px-1 rounded-lg dark:bg-graphite-400">
          <h3 className="text-[0.563rem] text-graphite-800 dark:text-snow-100">
            {tool.default_model?.name}
          </h3>
        </div> */}
        {tool?.multi_default_models?.length > 1 ? (
          <div className={'flex items-center'}>
            <Tooltip
              title={
                <span
                  dangerouslySetInnerHTML={{
                    __html: tool.multi_default_models
                      .map((model) => model.name)
                      .join('<br>'),
                  }}
                />
              }
              arrow
            >
              <p className="flex items-center uppercase group-hover:bg-flax-600 bg-snow-900 px-2 rounded-md text-[9px] leading-3 w-fit font-lexend dark:bg-graphite-400 dark:text-snow-100 dark:group-hover:text-graphite-900">
                {tool?.multi_default_models[0]?.name} +{' '}
                {tool?.multi_default_models?.length - 1}
                <SparklesIcon className="w-[10px] h-[10px] mr-1 ml-1" />
              </p>
            </Tooltip>
          </div>
        ) : tool?.multi_default_models?.length == 1 ? (
          <>
            <p className=" uppercase group-hover:bg-flax-600 bg-snow-900 px-2  rounded-md text-[9px] leading-3 w-fit font-lexend dark:bg-graphite-400 dark:text-snow-100 dark:group-hover:text-graphite-900">
              {tool?.multi_default_models[0]?.name}
            </p>
          </>
        ) : tool?.default_model ? (
          <>
            <p className="uppercase group-hover:bg-flax-600 bg-snow-900 px-2  rounded-md text-[9px] leading-3 w-fit font-lexend dark:bg-graphite-400 dark:text-snow-100 dark:group-hover:text-graphite-900">
              {tool?.default_model?.name}
            </p>
          </>
        ) : imageGenerationTools.includes(tool?._id) ? (
          <p className="uppercase group-hover:bg-flax-600 bg-snow-900 px-2  rounded-md text-[9px] leading-3 w-fit font-lexend dark:bg-graphite-400 dark:text-snow-100 dark:group-hover:text-graphite-900">
            {setImageToolNames(tool)}
          </p>
        ) : null}
        {!isSmallMobile && (
          <p className="text-graphite-600 dark:text-snow-100 text-sm mt-1 font-barlow overflow-hidden h-20">
            {tool.desc}
          </p>
        )}

        <div className="flex mt-2 space-x-2 align-middle items-center">
          <div className="flex space-x-1 items-center">
            {/* <UserCircleIcon className={'h-4 w-6 '} /> */}
            {tool.userInfo?.avatar.svg ? (
              <div className="avatar">
                <div className="w-4 rounded">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tool.userInfo?.avatar.svg,
                    }}
                  />
                </div>
              </div>
            ) : (
              <BoltIcon className="w-4 transition rounded-full text-white bg-crayola-600" />
            )}
            <span className="text-graphite-600 dark:text-snow-100 text-[0.563rem] font-lexend uppercase">
              {tool.userInfo ? tool.userInfo?.email.split('@')[0] : 'Straico'}
            </span>

            {/* <span className="text-gray-600 text-sm">USERNAME</span> */}
          </div>
          <div>
            {tool.files.length > 0 && (
              <div className="flex space-x-1 items-center">
                <PaperClipIcon
                  className={'w-4 text-graphite-600 dark:text-snow-100'}
                />
                <span className="text-graphite-600 dark:text-snow-100 text-[0.563rem] font-lexend uppercase">
                  {tool.files.length} attachment
                </span>
              </div>
            )}
          </div>
          <div className="flex space-x-1 items-center">
            {/* <RocketLaunchIcon className={'w-4 text-graphite-600'} /> */}
            <span className="text-graphite-600 text-[0.563rem] font-lexend uppercase">
              {/* 65,478 RUNS */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateFav;
